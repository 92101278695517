<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import IconCloseCircle from '@/assets/icons/close-circle.svg'
import IconLoading from '@/assets/icons/loading.svg'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },

  /**
   * Sometimes the content passed through <slot> needs external data
   * in order to be completely rendered. In such cases we set `async`
   * to `true` to show a loading spinner while its <slot> content is
   * loading; that way we avoid layout shifts within the modal.
   *
   * To notify BaseModal that it has loaded, the component we pass
   * through <slot> should call `onLoaded` (available to it via
   * "Scoped Slots").
   *
   * Related docs:
   *  - https://vuejs.org/guide/components/slots.html#scoped-slots
   */
  async: {
    type: Boolean,
    default: false,
  },
  bgWhite: {
    type: Boolean,
    default: false,
  },
  showMessage: {
    type: Boolean,
    default: false,
  },
})

const { $modal } = useNuxtApp()

const isOpen = computed(() => $modal[props.name])
const loading = ref(props.async)

const close = () => {
  $modal.close(props.name)
  loading.value = props.async
}

onKeyStroke('Escape', close)
</script>

<template>
  <transition
    enter-from-class="opacity-0"
    enter-active-class="transition-opacity duration-200"
    leave-active-class="transition-opacity duration-500"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isOpen"
      class="fixed top-0 left-0 z-[60] flex h-full w-full items-center justify-center bg-s-900 bg-opacity-95"
      @click.self="close"
    >
      <IconLoading v-show="loading" class="my-28 h-7 w-7 text-p-500" />

      <div
        v-show="!loading"
        class="relative flex max-h-[calc(100vh_-_2rem)] py-14"
        :class="props.bgWhite ? 'bg-white' : 'bg-s-50'"
      >
        <div class="overflow-auto px-4 md:px-10">
          <button
            :data-testid="`close-${name}-modal-btn`"
            class="absolute right-4 top-4 z-10"
            @click.prevent="$modal.close(name)"
          >
            <IconCloseCircle class="h-9 w-9" />
          </button>
          <slot v-if="!showMessage" :on-loaded="() => (loading = false)" />
        </div>
      </div>
    </div>
  </transition>
</template>
